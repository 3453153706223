import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import reducers from "../reducers";
import axios from "axios";
import { isAuth } from "./middlewares";
import thunk from "redux-thunk";
import rootReducer, { templateReducers } from "../redux/root-reducer";
import { getUser, logout } from "./oidc";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URI,
  responseType: "json",
});

// Add request interceptor with async token handling
axiosInstance.interceptors.request.use(async (config: any) => {
  try {
    // First try to get auth info from Redux store
    const globalReducer = store.getState().globalReducer;
    const languageReducer: any = store.getState().LanguageSwitcher;
    const storefrontId = globalReducer.authUser?.storefront_id;
    const tenant_id = globalReducer.authUser?.tenant?.id ?? "";
    
    // Set language header
    config.headers["Accept-Language"] = languageReducer?.language?.locale || 'en';
    
    // Get token - first try Redux store, then fallback to OIDC
    let token = globalReducer.authUser?.token;
    
    // If no token in Redux store, try to get from OIDC
    if (!token) {
      console.log('No token in Redux store, checking OIDC');
      const user = await getUser();
      if (user && user.access_token) {
        token = user.access_token;
        console.log('Found token in OIDC user manager');
      }
    }
    
    // Set authorization header if we have a token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Set query parameters
    const params = {
      ...config.params,
    };
    
    if (tenant_id) {
      params.tenant_id = tenant_id;
    }
    
    if (storefrontId && !config.url.includes('/v3/product/products')) {
      params.storefront_id = storefrontId;
    }
    
    config.params = params;
    return config;
  } catch (e) {
    console.error('Error in axios interceptor:', e);
    return config;
  }
});

// Add response interceptor to handle authentication errors
axiosInstance.interceptors.response.use(
  response => response, // Return successful responses as-is
  async error => {
    // Check for 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      console.error('401 Unauthorized response received:', error.response);
      
      // Log out the user and redirect to sign in
      console.log('Unauthorized API response - logging out user');
      await logout();
      // Note: no need to redirect here as logout() already handles that
    }
    
    // Return the error for further handling
    return Promise.reject(error);
  }
);

export const history = createBrowserHistory();

// preventing pushing same route to history
const prevHistoryPush = history.push;
let lastLocation: any = history.location;
history.listen((location) => {
  lastLocation = location;
});
history.push = (pathname: any, state = {}) => {
  if (
    lastLocation === null ||
    pathname !==
    lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(pathname, state);
  }
};
// end preventing pushing same route to history

const historyMiddleware = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();

let composer;
let middleware = [sagaMiddleware, isAuth, historyMiddleware, thunk];
if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  middleware.push(logger);
} else {
  composer = compose; // add support for Redux dev tools
}

const storeReducers = combineReducers({
  ...reducers,
  ...templateReducers,
  router: connectRouter(history),
});

export const store = createStore(
  storeReducers,
  composer(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof storeReducers>;
