import React, { useEffect } from "react";
import IUser from "../../dtos/IUser";

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

interface IntercomFunction {
  (): void;
  c: (args: any) => void;
  q: any[];
}

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

interface Props {
  user: {
    user_info: {
      user_id: number;
      full_name: string;
      email: string;
      creation_date: string;
    };
  };
}

const IntercomProvider: React.FC<Props> = ({ user }) => {
  useEffect(() => {
    if (process.env.REACT_APP_PORTAL_NAME !== "patient") {
      return;
    }

    if (!APP_ID) {
      console.warn("Intercom APP_ID is not configured");
      return;
    }

    if (!user?.user_info) {
      console.warn("User info is not available");
      return;
    }

    // Initialize Intercom settings
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: APP_ID,
      user_id: user.user_info.user_id.toString(),
      name: user.user_info.full_name,
      email: user.user_info.email,
      created_at: Math.floor(new Date(user.user_info.creation_date).getTime() / 1000), // Convert to Unix timestamp
    };

    // Load Intercom script
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = (() => {}) as unknown as IntercomFunction;
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + APP_ID;
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
  }, [user]);

  return null;
};

export default IntercomProvider;
