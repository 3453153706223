import React, { createContext, useEffect, useMemo, useState } from "react";
import GlobalStyles from "./assets/styles/globalStyle";
import Routes from "./router";
import AppProvider from "./AppProvider";
import { History } from "history";
import AuthRoute from "./containers/authRoute";
import { ConnectedRouter } from "connected-react-router";
import "./assets/sass/base/_base.scss";
import "./assets/sass/base/_global.scss";
import { datadogRum } from "@datadog/browser-rum";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./assets/styles/theme/index.css";
import IntercomProvider from "./components/IntercomProvider";
import { useSelector } from "react-redux";

interface IAppProps {
  history: History;
}

export interface ITitleConterxt {
  title: string;
  setTitle: (val: string) => void;
}

export const TitleContext: any = createContext({
  title: "",
  setTitle: () => {},
});

const App = (props: IAppProps) => {
  const [title, setTitle] = useState();
  const queryClient = new QueryClient();
  const user = useSelector((state: any) => state.globalReducer.authUser);
  console.log("user", user);
  const value: any = useMemo(() => ({ title, setTitle }), [title]);
  useEffect(() => {
    // if (process.env.REACT_APP_IS_VENDOR != 'true') {
    //   datadogRum.init({
    //     applicationId: process.env.REACT_APP_DATADOG_APP_ID || "",
    //     clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
    //     site: 'datadoghq.eu',
    //     service: 'staff',
    //     version: process.env.REACT_APP_VERSION_CODE ? process.env.REACT_APP_VERSION_CODE : '1.0.0',
    //     env: process.env.REACT_APP_ENVIRONMENT,
    //     sessionSampleRate: 100,
    //     sessionReplaySampleRate: 100,
    //     trackUserInteractions: true,
    //     trackResources: true,
    //     trackLongTasks: true,
    //     defaultPrivacyLevel: 'mask-user-input'
    //   });
    //   datadogRum.startSessionReplayRecording();
    // }
    // //firebase for vendor
    // if (process.env.REACT_APP_IS_VENDOR == 'true') {
    //   const firebaseConfig = {
    //     apiKey: "AIzaSyC_q_bLCR-APQB5BsxY0L_tOBjnN-KrCFM",
    //     authDomain: "debtacartsapp.firebaseapp.com",
    //     databaseURL: "https://debtacartsapp.firebaseio.com",
    //     projectId: "debtacartsapp",
    //     storageBucket: "debtacartsapp.appspot.com",
    //     messagingSenderId: "619043821855",
    //     appId: "1:619043821855:web:0bb3db83e5733d4b9e382a",
    //     measurementId: "G-X399PH9WQS"
    //   };
    //   const app = initializeApp(firebaseConfig);
    //   const analytics = getAnalytics(app);
    // }
  }, []);
  return (
    <ConnectedRouter history={props.history}>
      <AuthRoute>
        <TitleContext.Provider value={value}>
          <AppProvider>
            <QueryClientProvider client={queryClient}>
              <GlobalStyles />
              {user?.user_info && <IntercomProvider user={user} />}
              <Routes />
            </QueryClientProvider>
          </AppProvider>
        </TitleContext.Provider>
      </AuthRoute>
    </ConnectedRouter>
  );
};
export default App;
