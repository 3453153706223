import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { updateAuthUserPropsSaga } from '../../sagas/global/types';
// import LoginPage from '../../pages/login';
// import InvitationValidationPage from "../../pages/invitationValidation"


interface IAuthRouteProps {
    children: React.ReactNode
}

function AuthRoute(props: IAuthRouteProps) {
    const dispatch = useDispatch();
    const globalReducer = useSelector((state: AppState) => state.globalReducer);
    // Keep the same environment variable name for backward compatibility
    const loginWithOidc: boolean = (process.env.REACT_APP_AUTH_KEY_CLOAK && process.env.REACT_APP_AUTH_KEY_CLOAK.toLowerCase() == 'true') ? true : false;
    
    useEffect(() => {
        console.log('AuthRoute: Current path:', window.location.pathname);
        
        // Skip authentication if we're in the callback route
        if (window.location.pathname === '/callback') {
            console.log('AuthRoute: On callback page, skipping authentication');
            return;
        }
        
        if (!window.location.pathname.includes('callback') && !window.location.hash.includes('invitationToken')) {
            console.log('AuthRoute: Triggering authentication');
            dispatch(updateAuthUserPropsSaga({ authWithKeycloak: loginWithOidc }));
        }
    }, []);

    console.log('AuthRoute state:', globalReducer.authUser);

    let view;
    // if (window.location.hash.includes('invitationToken')) {
    //     view = (<InvitationValidationPage />)
    // }
    // else
    if (window.location.pathname === '/callback') {
        // Always render children when on the callback page
        view = props.children;
    }
    else if (globalReducer.authUser === undefined || globalReducer.authUser.token === null) {
        // Loading state handled by the saga
    }
    else if (globalReducer.authUser === undefined) {
        // Loading state handled by the saga
    }
    else if (!globalReducer.authUser.userValidated) {
        // view = (<p>user not validated...</p>)
    }
    else if (globalReducer.authUser.error) {
        view = globalReducer.authUser.error;
    }
    else {
        view = props.children;
    }
    return (
        <>
            {
                view
            }
        </>
    )
}

export default AuthRoute;