import { User, UserManager, UserManagerSettings, WebStorageStateStore, Log } from "oidc-client-ts";
import { store } from "./index";
import { homeLocations } from "../assets/constants";
import { updateAuthUserProps } from "../reducers/global/types";

// Enable logging for debugging
Log.setLogger(console);
Log.setLevel(Log.INFO);

// Get the appropriate home route based on portal name
const getHomeRoute = () => {
  const portalName = process.env.REACT_APP_PORTAL_NAME;
  return homeLocations[portalName] || '/';
};

// OIDC client configuration
export const oidcConfig: UserManagerSettings = {
  authority: process.env.REACT_APP_KEY_CLOAK_URL + "/realms/" + process.env.REACT_APP_KEY_CLOAK_REALM,
  client_id: process.env.REACT_APP_KEY_CLOAK_CLIENT_ID || "",
  redirect_uri: window.location.origin + "/callback",
  post_logout_redirect_uri: window.location.origin,
  response_type: "code",
  scope: "openid profile email",
  automaticSilentRenew: true,
  silent_redirect_uri: window.location.origin + "/silent-refresh.html",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  loadUserInfo: true,
  // monitorSession: true,
  // Adding debug logging
  filterProtocolClaims: true,
};

// Log config for debugging
console.log('OIDC Config:', {
  authority: oidcConfig.authority,
  client_id: oidcConfig.client_id,
  redirect_uri: oidcConfig.redirect_uri,
});

export const userManager = new UserManager(oidcConfig);

// Add event listeners for debugging
userManager.events.addUserLoaded((user) => {
  console.log('User loaded:', user);
  
  // Update the Redux store with the refreshed token
  if (user && user.access_token) {
    console.log('Updating Redux store with refreshed token');
    const currentAuthUser = store.getState().globalReducer.authUser;
    if (currentAuthUser) {
      store.dispatch(updateAuthUserProps({ 
        authUser: { 
          ...currentAuthUser, 
          token: user.access_token,
          expires_at: user.expires_at,
          idTokenParsed: user.profile
        }
      }));
    }
  }
});

userManager.events.addSilentRenewError((error) => {
  console.error('Silent renew error:', error);
  console.error('Silent renew error message:', error.message);
  console.error('Silent renew error stack:', error.stack);
  if ((error as any).error) {
    console.error('Silent renew error type:', (error as any).error);
  }
  
  // Clear storage and redirect to sign in on silent renewal failure
  localStorage.clear();
  userManager.removeUser().then(() => {
    console.log('User removed after silent renewal failure');
    window.location.href = '/signin';
  }).catch(err => {
    console.error('Error removing user after silent renewal failure:', err);
    window.location.href = '/signin';
  });
});

userManager.events.addUserSignedOut(() => {
  console.log('User signed out');
});

// Helper functions
export const getUser = (): Promise<User | null> => {
  return userManager.getUser();
};

export const login = (): Promise<void> => {
  console.log('Redirecting to login...');
  return userManager.signinRedirect();
};

export const logout = async (): Promise<void> => {
  try {
    // Clear any token renewal interval
    if (window.tokenRenewalInterval) {
      clearInterval(window.tokenRenewalInterval);
      window.tokenRenewalInterval = undefined;
    }

    // Get current user before clearing storage
    const user = await userManager.getUser();
    console.log('Current user at logout:', user);
    
    // If we have a user, perform a complete signout from Keycloak
    if (user && user.id_token) {
      console.log('Starting Keycloak session termination');
      
      // We only want to clear storage AFTER we get the user
      // but BEFORE we redirect (so we're logged out locally)
      localStorage.clear();
      
      // Remove user from OIDC storage
      await userManager.removeUser();
      
      // This is critical - we need to let the signoutRedirect complete
      // without interrupting it with our own redirect
      return userManager.signoutRedirect({
        id_token_hint: user.id_token,
        post_logout_redirect_uri: window.location.origin
      });
    } else {
      // No valid user session, just clear everything and redirect
      console.log('No valid user session found, clearing local data only');
      localStorage.clear();
      await userManager.removeUser();
      window.location.href = '/signin';
    }
  } catch (error) {
    console.error('Logout error:', error);
    // Clear everything as a failsafe
    localStorage.clear();
    await userManager.removeUser();
    // Fallback to direct navigation
    window.location.href = '/signin';
  }
};

export const renewToken = (): Promise<User | null> => {
  console.log('Manual token renewal requested');
  return userManager.signinSilent()
    .then(user => {
      if (user && user.access_token) {
        console.log('Token manually renewed successfully');
        // The userLoaded event handler will update the Redux store
      }
      return user;
    })
    .catch(error => {
      console.error('Manual token renewal failed:', error);
      throw error;
    });
};

// Add token to API calls
export const addTokenToRequest = async (url: string, options: RequestInit = {}): Promise<RequestInit> => {
  const user = await getUser();
  if (user && user.access_token) {
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${user.access_token}`,
    };
    return { ...options, headers };
  }
  return options;
}; 