import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  UPDATE_APP_STATUS_SAGA,
  IUpdateAppStatusActionSaga,
  UPDATE_AUTH_TOKEN_SAGA,
  IUpdateAuthTokenActionSAGA,
  UPDATE_AUTH_USER_SAGA,
  IUpdateAuthUserActionSAGA,
  UPDATE_GLOBAL_MODAL_SAGA,
  IUpdateGlobalModalActionSAGA,
  LOGIN_AUTH_USER_SAGA,
  ILoginAuthUserActionSAGA,
  IUpdateWebsocketClientActionSAGA,
  UPDATE_WEBSOCKET_CLIENT_SAGA,
} from "./types";
import {
  updateStatusProps,
  updateAuthTokenExpProps,
  updateAuthUserProps,
  updateGlobalModalProps,
  updateWebsocketClientProps,
} from "../../reducers/global/types";
import { validateUser } from "../../api/users";
import { getUser, renewToken, userManager, login as oidcLogin } from "../../store/oidc";
import usersList from "../../users.json";
import { login as loginApi } from "../../api/auth";
import { push } from "connected-react-router";
import IBranch from "../../dtos/IBranch";
import { store } from "../../store";
import Sales from "../../containers/Users/Sales";
import { fetchVendor, verifyVendor } from "../../api/vendors";

declare global {
  interface Window {
    tokenRenewalInterval?: NodeJS.Timeout;
  }
}

function* updateAppStatus(action: IUpdateAppStatusActionSaga) {
  yield put(updateStatusProps({ appStatus: action.payload.appStatus }));
}

export function* watchUpdateAppStatus() {
  yield takeEvery(UPDATE_APP_STATUS_SAGA, updateAppStatus);
}

function* updateAuthTokenExp(action: IUpdateAuthTokenActionSAGA) {
  yield put(updateAuthTokenExpProps({ authTokenExp: action.payload.authTokenExp }));
}

export function* watchUpdateAuthTokenExp() {
  yield takeLatest(UPDATE_AUTH_TOKEN_SAGA, updateAuthTokenExp);
}

function* updateAuthUser(action: IUpdateAuthUserActionSAGA) {
  console.log('Starting authentication process', action.payload);
  let authUser;
  
  // authenticate with OIDC
  if (action.payload.authWithKeycloak) {
    try {
      // Check if we're in a callback page - if so, don't trigger login again
      if (window.location.pathname.includes('/callback')) {
        console.log('On callback page, skipping authentication');
        return;
      }
      
      // Check if user is already logged in
      console.log('Checking for existing user');
      let user = yield call(getUser);
      console.log('User from getUser:', user);
      
      if (!user) {
        // If not logged in, redirect to login - will return to callback route after auth
        console.log('No user found, redirecting to login');
        yield call(oidcLogin);
        return; // Stop execution until redirect happens
      }
      // // Setup token renewal - running every minute
      // if (!window.tokenRenewalInterval) {
      //   console.log('Setting up token renewal interval');
      //   window.tokenRenewalInterval = setInterval(() => {
      //     console.log('Attempting token renewal');
      //     renewToken()
      //       .then((refreshedUser) => {
      //         if (refreshedUser) {
      //           console.log('Token refreshed successfully');
      //           const currentAuthUser = store.getState().globalReducer.authUser;
      //           store.dispatch(updateAuthUserProps({ 
      //             authUser: { 
      //               ...currentAuthUser, 
      //               token: refreshedUser.access_token,
      //               expires_at: refreshedUser.expires_at,
      //               idTokenParsed: refreshedUser.profile
      //             } 
      //           }));
      //         }
      //       })
      //       .catch((error) => {
      //         console.error('Failed to refresh token:', error);
      //         // Clear localStorage when token refresh fails
      //         localStorage.clear();
      //                       // If refresh fails and token is expired, redirect to login
      //                       // const currentUser = store.getState().globalReducer.authUser;
      //                       // if (currentUser && currentUser.expires_at && new Date().getTime() > currentUser.expires_at * 1000) {
      //                       //   console.log('Token expired, redirecting to login');
      //                       //   oidcLogin();
      //                       // }
      //         // Always redirect to login when refresh fails
      //         console.log('Token refresh failed, redirecting to login');
      //         oidcLogin();
      //       });
      //   }, 60000); // Check every minute
      // }
      // Setup token renewal - running every minute
      // if (!window.tokenRenewalInterval) {
      //   console.log('Setting up token renewal interval');
      //   window.tokenRenewalInterval = setInterval(() => {
      //     console.log('Attempting token renewal');
      //     renewToken()
      //       .then((refreshedUser) => {
      //         console.log('Token renewal completed');
      //         // No need to manually update Redux store here
      //         // The userManager.events.addUserLoaded handler will handle it
      //       })
      //       .catch((error) => {
      //         console.error('Failed to refresh token:', error);
      //         // Silent renewal error handler will handle this
      //       });
      //   }, 60000); // Check every minute
      // }

      // Format user info to match previous structure expected by the app
      console.log('Formatting user info from:', user);
      authUser = {
        authenticated: true,
        token: user.access_token,
        idTokenParsed: user.profile,
        subject: user.profile.sub,
        email: user.profile.email,
        given_name: user.profile.given_name,
        family_name: user.profile.family_name
      };
      console.log('Formatted authUser:', authUser);
    } catch (error) {
      console.error('OIDC login error:', error);
      authUser = {
        error: "failed to login with OIDC: " + (error.message || 'Unknown error'),
      };
    }
  } else {
    // authenticate with basic
    console.log('Using basic authentication');
    authUser = {
      token: localStorage.getItem("authToken"),
    };
  }
  
  console.log('Updating auth user state:', authUser);
  yield put(updateAuthUserProps({ authUser: authUser }));
  
  // Skip validation if no authUser or error
  if (!authUser || authUser.error) {
    console.log('Auth user has error or is missing, skipping validation');
    return;
  }
  
  try {
    console.log('Validating user with backend');
    const validationData: {
      userValidated: boolean;
      service_account_id: number | null;
      my_branches: IBranch[] | null;
      user_permissions: { id: string; name: string }[];
      user_id: number | null;
      user_profile: any;
      storefronts: any;
      user_info: any;
      user_email: any;
      user_first_name: any;
      user_last_name: any;
    } = yield call(validateUser, authUser);
    
    console.log('User validation result:', validationData);
    
    yield localStorage.setItem(
      "user_id",
      validationData.user_id ? validationData.user_id.toString() : ""
    );

    // Create user_info object if it's not present in validationData
    const user_info = validationData.user_info || {
      user_id: validationData.user_id,
      first_name: validationData.user_first_name || authUser.given_name,
      last_name: validationData.user_last_name || authUser.family_name,
      email: validationData.user_email || authUser.email,
      full_name: `${validationData.user_first_name || authUser.given_name} ${
        validationData.user_last_name || authUser.family_name
      }`,
      creation_date: new Date().toISOString(),
    };

    authUser = {
      ...authUser,
      user_permissions: validationData.user_permissions,
      my_branches: validationData.my_branches,
      userValidated: validationData.userValidated,
      user_id: validationData.user_id,
      tenant: validationData.user_profile?.tenant,
      storefronts: validationData.storefronts,
      email: validationData.user_email || authUser.email,
      first_name: validationData.user_first_name || authUser.given_name,
      last_name: validationData.user_last_name || authUser.family_name,
      user_info,
      user_profile: validationData.user_profile,
    };
    
    if (process.env.REACT_APP_IS_VENDOR == "true") {
      console.log('Fetching vendor data');
      const vendorData = yield call(fetchVendor, authUser.tenant?.id);

      if (vendorData) {
        yield localStorage.setItem("storefront", vendorData.storefronts[0]?.storefront_id);
        authUser = {
          ...authUser,
          vendor_id: vendorData.id,
          storefront_id: vendorData.storefronts[0]?.storefront_id,
        };
      } else {
        console.log('No vendor data found');
        return;
      }
    } else {
      if (validationData.storefronts && validationData.storefronts.length > 0) {
        let selectedStoreFront = validationData.storefronts.find(
          (storefront) => storefront.storefront_id == localStorage.getItem("storefront")
        );
        if (selectedStoreFront) {
          yield localStorage.setItem("storefront", selectedStoreFront.storefront_id);
          authUser = {
            ...authUser,
            storefront_id: selectedStoreFront?.storefront_id,
          };
        } else {
          yield localStorage.setItem("storefront", validationData.storefronts[0].storefront_id);
          authUser = {
            ...authUser,
            storefront_id: validationData.storefronts[0]?.storefront_id,
          };
        }
      } else {
        yield localStorage.setItem("storefront", "1");
        authUser = {
          ...authUser,
          storefront_id: "1",
        };
      }
    }
    
    console.log('Final auth user state:', authUser);
    yield put(updateAuthUserProps({ authUser: authUser }));
    
    let salesPer = authUser.user_permissions?.find((permission: { id: string; name: string }) => {
      return permission.name === "USER_SALES_STAFF_ASSIGN";
    });
    
    if (salesPer) Sales.getInstance();
  } catch (error) {
    console.error('User validation error:', error);
    // Keep the auth user but mark validation as failed
    yield put(updateAuthUserProps({ 
      authUser: { 
        ...authUser, 
        userValidated: false,
        error: "Failed to validate user: " + (error.message || 'Unknown error')
      } 
    }));
  }
}

export function* watchUpdateAuthUser() {
  yield takeLatest(UPDATE_AUTH_USER_SAGA, updateAuthUser);
}

function* updateGlobalModal(action: IUpdateGlobalModalActionSAGA) {
  yield put(updateGlobalModalProps({ ...action.payload }));
}

export function* watchUpdateGlobalModal() {
  yield takeLatest(UPDATE_GLOBAL_MODAL_SAGA, updateGlobalModal);
}

function* loginAuthUser(action: ILoginAuthUserActionSAGA) {
  let authUser;
  const results: { token: string; message?: string } = yield call(
    loginApi,
    action.payload.email,
    action.payload.password
  );
  const existingUser: any = usersList.find((u) => u.email == action.payload.email);
  if (existingUser) {
    if (results.token) {
      localStorage.setItem("authToken", results.token);
      authUser = {
        token: results.token,
      };
    } else if (results.message) {
      alert(results.message);
    }
  } else {
    alert("User not found");
  }
  yield put(updateAuthUserProps({ authUser: authUser }));
}

export function* watchLoginAuthUser() {
  yield takeLatest(LOGIN_AUTH_USER_SAGA, loginAuthUser);
}

function* updateWebsocketClient(action: IUpdateWebsocketClientActionSAGA) {
  yield put(updateWebsocketClientProps({ ...action.payload }));
}

export function* watchWebsocketClient() {
  yield takeLatest(UPDATE_WEBSOCKET_CLIENT_SAGA, updateWebsocketClient);
}

export default [
  watchUpdateAppStatus(),
  watchUpdateAuthTokenExp(),
  watchUpdateAuthUser(),
  watchUpdateGlobalModal(),
  watchLoginAuthUser(),
  watchWebsocketClient(),
];
