import { takeLatest, call } from "redux-saga/effects";
import { LOGOUT_SAGA, ILogoutActionSaga } from "./types";
import { logout } from "../../store/oidc";

function* logOut(action: ILogoutActionSaga) {
  try {
    yield call(logout);
  } catch (error) {
    console.error('Logout saga error:', error);
    // Fallback to direct navigation if logout fails
    window.location.href = "/signin";
  }
}

export function* watchLogOut() {
  yield takeLatest(LOGOUT_SAGA, logOut);
}

export default [
  watchLogOut(),
]